









import { Component, Vue, Prop } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";

@Component
export default class DraftInvoiceButton extends Vue {
  public name = "DraftInvoiceButton";

  @Prop({ default: () => uuidv4() })
  public id!: string;

  @Prop()
  public tooltipLabel: any;

  @Prop({ default: () => false })
  public disabled!: boolean;
}
